import React, { PureComponent } from 'react';

class PageHeroVideo extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            canPlayVideo: true,
        };
        this.videoTimeout = null;
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        this.videoTimeout = setTimeout(() => {
            if (this.videoRef && this.videoRef.current) {
                this.videoRef.current.play()
                    .catch((e) => {
                        this.setState({ canPlayVideo: false });
                    });
            } else {
                this.setState({ canPlayVideo: false });
            }
        }, 0);
    }

    componentWillUnmount() {
        clearTimeout(this.videoTimeout);
    }

    fallbackImgSrc() {
        const { featuredImageUrl } = this.props;
        return featuredImageUrl;
    }

    render() {
        const { canPlayVideo } = this.state;
        const { videoUrl } = this.props;

        return (
            <div className="pageHeroVideo">
                {canPlayVideo && (
                    <video
                        ref={this.videoRef}
                        loop
                        id="pageVideoBg"
                        className="videoBg"
                        muted
                    >
                        <source src={videoUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                )}
                {!canPlayVideo && (
                    <div className="fallbackImg">
                        <img
                            src={this.fallbackImgSrc()}
                            alt=""
                        />
                    </div>
                )}
            </div>
        )
    }
}

export default PageHeroVideo;
