import React, { PureComponent } from 'react';

class VideoOverlay extends PureComponent {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        if (this.videoRef && this.videoRef.current) {
            this.videoRef.current.play()
                .catch((e) => {
                    // TODO
                });
        }
    }

    componentDidUpdate() {
        if (!this.props.isOpen) {
            this.videoRef.current.pause();
        }
    }

    render() {
        return (
            <div className="videoOverlay">
                <video
                    ref={this.videoRef}
                    loop
                    id="reelVideo"
                    class="reelVideo"
                >
                    <source src="https://wordpress.triggermoments.com/wp-content/themes/triggerhouse/src/triggerhouse_sizzle_2024.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                <div
                    className="iconClose"
                    onClick={() => this.props.onToggleLightbox(false)}
                ></div>
            </div>
        );
    }
}

export default VideoOverlay;
